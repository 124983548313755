

import React from 'react'
import SeoPage from '../components/SeoPage'


const ContentPage19 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Obtenez 1 soumission d'avocat pour Droit civil, administratif ou du travail - Soumissions Avocat"
        description="Entourez-vous d’un avocat compétent pour votre cause en droit civil, droit du travail ou droit administratif: assurance automobile (SAAQ) autre assurance congédiement convention collective diffamation/harcèlement dommages corporels/matériels droits d’auteur droits et libertés fondamentales droit/normes des travailleurs (CNESST) immigration (citoyenneté/permis) mise en demeure/demande de remboursement recours collectif responsabilité civile troubles de LIRE PLUS"
    >
        <h1>Obtenez 1 soumission d'avocat pour Droit civil, administratif ou du travail</h1><p><strong>Entourez-vous d’un avocat compétent pour votre cause en droit civil, droit du travail ou droit administratif:</strong></p>
        <ul>
            <li>assurance automobile (SAAQ)</li>
            <li>autre assurance</li>
            <li>congédiement</li>
            <li>convention collective</li>
            <li>diffamation/harcèlement</li>
            <li>dommages corporels/matériels</li>
            <li>droits d’auteur</li>
            <li>droits et libertés fondamentales</li>
            <li>droit/normes des travailleurs (CNESST)</li>
            <li>immigration (citoyenneté/permis)</li>
            <li>mise en demeure/demande de remboursement</li>
            <li>recours collectif</li>
            <li>responsabilité civile</li>
            <li>troubles de voisinage</li>
            <li>vie privée</li>
            <li>autre</li>
        </ul>
        <p><strong>Remplissez le formulaire sur cette page pour obtenir une offre de service gratuite d’un avocat partenaire de Soumissions Avocat.</strong></p>
        <p>Nous vous mettrons en contact avec un avocat digne de confiance dans votre secteur, et qui détient l’expertise requise pour votre cause en droit civil, administratif ou droit du travail. Ayez une idée rapide des services et taux horaires. Ce service est gratuit et sans engagement de votre part.</p>
    </SeoPage>
)
export default ContentPage19
